.App-title {
  font-size: 4.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;
}

.App-route {
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: center;
}