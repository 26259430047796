.table-container {
    width: 80%;
    height: auto;
    margin-bottom: 100px;
}

.table-title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}