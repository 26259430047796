.widget-container {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
}

.form-container {
    border: 0.2px solid lightgrey; 
    padding: 10px;
    width: 100%;
}